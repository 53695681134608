@import "colors";

.active {
  color: $action
}

.clickable {
  &:hover {
    color: $action;
    cursor: pointer;
  }
}

.active.clickable {
  &:hover {
    opacity: 0.6;
  }
}