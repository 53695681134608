@import "~react-image-gallery/styles/css/image-gallery.css";

/**********************************************/
/********************* FONTS ******************/
/**********************************************/

@font-face {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  src: local("Arial CE"), url("./style/fonts/arial/ArialCE.ttf") format("ttf");
}

@font-face {
  font-family: "Arial";
  font-style: normal;
  font-weight: bold;
  src: local("Arial CE Bold"),
    url("./style/fonts/arial/ArialCEBold.ttf") format("ttf");
}

@font-face {
  font-family: "Arial";
  font-style: italic;
  font-weight: bold;
  src: local("Arial CE Bold Italic"),
    url("./style/fonts/arial/ArialCEBoldItalic.ttf") format("ttf");
}

@font-face {
  font-family: "Arial";
  font-style: italic;
  font-weight: normal;
  src: local("Arial CE Italic"),
    url("./style/fonts/arial/ArialCEItalic.ttf") format("ttf");
}

@font-face {
  font-family: "Century";
  font-style: normal;
  font-weight: normal;
  src: local("Century"),
    url("./style/fonts/century/CenturyNormal.ttf") format("ttf");
}

@font-face {
  font-family: "Century";
  font-style: normal;
  font-weight: bold;
  src: local("Century Bold"),
    url("./style/fonts/century/CenturyBold.ttf") format("ttf");
}

@font-face {
  font-family: "Century";
  font-style: italic;
  font-weight: bold;
  src: local("Century Bold Italic"),
    url("./style/fonts/century/CenturyBoldItalic.ttf") format("ttf");
}
