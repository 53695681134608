$green: #00ff00;
$yellow: #ffeb3b;
$orange: #ff9800;
$red: #f44336;
$blue: #2196f3;

$primary: #2196f3;
$secondary: #607d8b;
$weakFont1: #424242;
$weakFont2: #888888;
$weakBackground1: #fafafa;
$weakBackground2: #e0e0e0;

$primaryBackground: #000000;
$secondaryBackground: #0a0a0c;
$lightBackground: #161616;
$primaryFont: #ffffff;
$secondaryFont: #606060;
$action: #f0b849;

:export {
  green: $green;
  yellow: $yellow;
  orange: $orange;
  red: $red;
  blue: $blue;

  primary: $primary;
  secondary: $secondary;
  weakFont1: $weakFont1;
  weakFont2: $weakFont2;
  weakBackground1: $weakBackground1;
  weakBackground2: $weakBackground2;

  primaryBackground: $primaryBackground;
  secondaryBackground: $secondaryBackground;
  lightBackground: $lightBackground;
  primaryFont: $primaryFont;
  secondaryFont: $secondaryFont;
  action: $action;
}
