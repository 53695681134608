@import "colors";

.image-gallery-thumbnail:hover {
  cursor: pointer;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 1px solid $action;
}

.image-gallery-icon:hover {
  color: $action;
}

.image-gallery-index {
  background: none;
  font-family: "Arial";
  font-weight: bold;
}

.image-gallery-custom-picture img {
  max-height: 80vh;
  object-fit: contain;
}