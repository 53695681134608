@import "style/_colors";

/**********************************************/
/******************** GLOBAL ******************/
/**********************************************/

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Arial";
  user-select: none;
  background-color: $primaryBackground;
  color: $primaryFont;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

h1 {
  text-align: center;
  font-weight: normal;
  font-family: "Century";
  text-transform: uppercase;
}

h2 {
  text-align: center;
  font-family: "Century";
  font-weight: normal;
  text-transform: uppercase;
}

h3 {
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
}

h4 {
  margin-bottom: 0.5em;
  font-weight: normal;
}

/* Override BrainhubCarousel */

.BrainhubCarousel__thumbnail {
  background-color: transparent;
}

.BrainhubCarousel__dots {
  overflow: hidden;
}
